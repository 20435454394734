import * as _crypto2 from "crypto";

var _crypto = "default" in _crypto2 ? _crypto2.default : _crypto2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scriptSha1 = exports.defineScript = void 0;
  const crypto_1 = _crypto;

  function defineScript(script) {
    return { ...script,
      SHA1: scriptSha1(script.SCRIPT)
    };
  }

  exports.defineScript = defineScript;

  function scriptSha1(script) {
    return (0, crypto_1.createHash)("sha1").update(script).digest("hex");
  }

  exports.scriptSha1 = scriptSha1;
  return exports;
}