import { dew as _commandsDew } from "./commands";
import { dew as _clusterSlotsDew } from "./cluster-slots";
import { dew as _commanderDew } from "../commander";
import * as _events2 from "events";

var _events = "default" in _events2 ? _events2.default : _events2;

import { dew as _multiCommandDew } from "./multi-command";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var __classPrivateFieldSet = exports && exports.__classPrivateFieldSet || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value), value;
  };

  var __classPrivateFieldGet = exports && exports.__classPrivateFieldGet || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
  };

  var _RedisCluster_instances, _RedisCluster_options, _RedisCluster_slots, _RedisCluster_Multi, _RedisCluster_execute;

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const commands_1 = _commandsDew();

  const cluster_slots_1 = _clusterSlotsDew();

  const commander_1 = _commanderDew();

  const events_1 = _events;

  const multi_command_1 = _multiCommandDew();

  class RedisCluster extends events_1.EventEmitter {
    constructor(options) {
      super();

      _RedisCluster_instances.add(this);

      _RedisCluster_options.set(this, void 0);

      _RedisCluster_slots.set(this, void 0);

      _RedisCluster_Multi.set(this, void 0);

      __classPrivateFieldSet(this, _RedisCluster_options, options, "f");

      __classPrivateFieldSet(this, _RedisCluster_slots, new cluster_slots_1.default(options, err => this.emit("error", err)), "f");

      __classPrivateFieldSet(this, _RedisCluster_Multi, multi_command_1.default.extend(options), "f");
    }

    static extractFirstKey(command, originalArgs, redisArgs) {
      if (command.FIRST_KEY_INDEX === undefined) {
        return undefined;
      } else if (typeof command.FIRST_KEY_INDEX === "number") {
        return redisArgs[command.FIRST_KEY_INDEX];
      }

      return command.FIRST_KEY_INDEX(...originalArgs);
    }

    static create(options) {
      return new ((0, commander_1.attachExtensions)({
        BaseClass: RedisCluster,
        modulesExecutor: RedisCluster.prototype.commandsExecutor,
        modules: options?.modules,
        functionsExecutor: RedisCluster.prototype.functionsExecutor,
        functions: options?.functions,
        scriptsExecutor: RedisCluster.prototype.scriptsExecutor,
        scripts: options?.scripts
      }))(options);
    }

    duplicate(overrides) {
      return new (Object.getPrototypeOf(this).constructor)({ ...__classPrivateFieldGet(this, _RedisCluster_options, "f"),
        ...overrides
      });
    }

    async connect() {
      return __classPrivateFieldGet(this, _RedisCluster_slots, "f").connect();
    }

    async commandsExecutor(command, args) {
      const {
        args: redisArgs,
        options
      } = (0, commander_1.transformCommandArguments)(command, args);
      return (0, commander_1.transformCommandReply)(command, await this.sendCommand(RedisCluster.extractFirstKey(command, args, redisArgs), command.IS_READ_ONLY, redisArgs, options), redisArgs.preserve);
    }

    async sendCommand(firstKey, isReadonly, args, options) {
      return __classPrivateFieldGet(this, _RedisCluster_instances, "m", _RedisCluster_execute).call(this, firstKey, isReadonly, client => client.sendCommand(args, options));
    }

    async functionsExecutor(fn, args, name) {
      const {
        args: redisArgs,
        options
      } = (0, commander_1.transformCommandArguments)(fn, args);
      return (0, commander_1.transformCommandReply)(fn, await this.executeFunction(name, fn, args, redisArgs, options), redisArgs.preserve);
    }

    async executeFunction(name, fn, originalArgs, redisArgs, options) {
      return __classPrivateFieldGet(this, _RedisCluster_instances, "m", _RedisCluster_execute).call(this, RedisCluster.extractFirstKey(fn, originalArgs, redisArgs), fn.IS_READ_ONLY, client => client.executeFunction(name, fn, redisArgs, options));
    }

    async scriptsExecutor(script, args) {
      const {
        args: redisArgs,
        options
      } = (0, commander_1.transformCommandArguments)(script, args);
      return (0, commander_1.transformCommandReply)(script, await this.executeScript(script, args, redisArgs, options), redisArgs.preserve);
    }

    async executeScript(script, originalArgs, redisArgs, options) {
      return __classPrivateFieldGet(this, _RedisCluster_instances, "m", _RedisCluster_execute).call(this, RedisCluster.extractFirstKey(script, originalArgs, redisArgs), script.IS_READ_ONLY, client => client.executeScript(script, redisArgs, options));
    }

    multi(routing) {
      return new (__classPrivateFieldGet(this, _RedisCluster_Multi, "f"))((commands, firstKey, chainId) => {
        return __classPrivateFieldGet(this, _RedisCluster_instances, "m", _RedisCluster_execute).call(this, firstKey, false, client => client.multiExecutor(commands, undefined, chainId));
      }, routing);
    }

    getMasters() {
      return __classPrivateFieldGet(this, _RedisCluster_slots, "f").getMasters();
    }

    getSlotMaster(slot) {
      return __classPrivateFieldGet(this, _RedisCluster_slots, "f").getSlotMaster(slot);
    }

    quit() {
      return __classPrivateFieldGet(this, _RedisCluster_slots, "f").quit();
    }

    disconnect() {
      return __classPrivateFieldGet(this, _RedisCluster_slots, "f").disconnect();
    }

  }

  exports.default = RedisCluster;
  _RedisCluster_options = new WeakMap(), _RedisCluster_slots = new WeakMap(), _RedisCluster_Multi = new WeakMap(), _RedisCluster_instances = new WeakSet(), _RedisCluster_execute = async function _RedisCluster_execute(firstKey, isReadonly, executor) {
    const maxCommandRedirections = __classPrivateFieldGet(this, _RedisCluster_options, "f").maxCommandRedirections ?? 16;

    let client = __classPrivateFieldGet(this, _RedisCluster_slots, "f").getClient(firstKey, isReadonly);

    for (let i = 0;; i++) {
      try {
        return await executor(client);
      } catch (err) {
        if (++i > maxCommandRedirections || !(err instanceof Error)) {
          throw err;
        }

        if (err.message.startsWith("ASK")) {
          const address = err.message.substring(err.message.lastIndexOf(" ") + 1);

          if (__classPrivateFieldGet(this, _RedisCluster_slots, "f").getNodeByAddress(address)?.client === client) {
            await client.asking();
            continue;
          }

          await __classPrivateFieldGet(this, _RedisCluster_slots, "f").rediscover(client);

          const redirectTo = __classPrivateFieldGet(this, _RedisCluster_slots, "f").getNodeByAddress(address);

          if (!redirectTo) {
            throw new Error(`Cannot find node ${address}`);
          }

          await redirectTo.client.asking();
          client = redirectTo.client;
          continue;
        } else if (err.message.startsWith("MOVED")) {
          await __classPrivateFieldGet(this, _RedisCluster_slots, "f").rediscover(client);
          client = __classPrivateFieldGet(this, _RedisCluster_slots, "f").getClient(firstKey, isReadonly);
          continue;
        }

        throw err;
      }
    }
  };
  (0, commander_1.attachCommands)({
    BaseClass: RedisCluster,
    commands: commands_1.default,
    executor: RedisCluster.prototype.commandsExecutor
  });
  return exports;
}